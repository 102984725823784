/* @flow */
import type { Platform, Provider } from "graphql-types/loadContent";

export const MEGABYTE = 1024 * 1024;

export const MAX_TWITTER_LENGTH = 280;
export const MAX_LINKEDIN_LENGTH = 3000;
export const MAX_PINTEREST_LENGTH = 500;
export const MAX_INSTAGRAM_LENGTH = 2200;
export const MAX_GOOGLE_LENGTH = 1500;

export const TWITTER_DM_ERROR_MSG = `Twitter content can not be
  formatted as a direct message. (Do not begin message with 'D', 'M', or 'DM'.)`;
export const MAX_UPLOADED_IMAGE_BYTES = 5 * MEGABYTE;
export const MAX_UPLOADED_IMAGE_DIMENSIONS_BY_TYPE = {
  "image/gif": [2048, 2048],
  "image/jpeg": [8192, 8192],
  "image/png": [8192, 8192]
};

export const VALID_ASPECT_RATIO_RANGE = {
  INSTAGRAM_DIRECT: {
    range: [0.795, 1.927], // The first element is the min, the second element the max
    errorMessage: `For Instagram Direct, image aspect ratio should be between 0.80 (portrait with 4:5 width-to-height ratio) and
    1.91 (landscape with 1.91:1 width-to-height ratio). The optimal size is 1080px by 1080px,
    1080px wide by 608px tall, or 1080px wide by 1350px tall.`
  }
};

export const IMAGE_VALIDATIONS = {
  size: MAX_UPLOADED_IMAGE_BYTES,
  types: (Object.keys(MAX_UPLOADED_IMAGE_DIMENSIONS_BY_TYPE): string[]),
  dimensions: MAX_UPLOADED_IMAGE_DIMENSIONS_BY_TYPE,
  aspectRatioRanges: VALID_ASPECT_RATIO_RANGE
};

// Twitter's requirements for Videos are here:
// https://dev.twitter.com/rest/media/uploading-media#chunkedupload
// Facebook's requirements are here:
// https://developers.facebook.com/docs/graph-api/video-uploads
// Currently, Twitter's requirements are generally more restrictive than Facebook's.
// -md 2017.04.26

const LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS = {
  SUPPORTS_VIDEO: true,
  MAX_UPLOADED_VIDEO_BYTES: 512 * MEGABYTE,
  MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
  MAX_UPLOADED_VIDEO_DIMENSIONS: [3840, 2160],
  MIN_UPLOADED_VIDEO_SECONDS: 2,
  MAX_UPLOADED_VIDEO_SECONDS: null, // Unlimited
  UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42", "qt"],
  MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
  MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
};

const NO_VIDEO_SUPPORT = {
  SUPPORTS_VIDEO: false,
  MAX_UPLOADED_VIDEO_BYTES: 0,
  MIN_UPLOADED_VIDEO_SECONDS: 0,
  MAX_UPLOADED_VIDEO_SECONDS: 0,
  UPLOADED_VIDEO_SUPPORTED_FORMATS: [],
  MIN_UPLOADED_VIDEO_DIMENSIONS: [0, 0],
  MAX_UPLOADED_VIDEO_DIMENSIONS: [0, 0],
  MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
  MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
};

export const VIDEO_REQUIREMENTS_BY_PLATFORM = {
  TWITTER: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 512 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    // Twitter seems to allow dimensions way beyond what they recommend, so I'm going
    // to leave the maxDimensions validation larger for now. -md 2017.04.26
    MAX_UPLOADED_VIDEO_DIMENSIONS: [4096, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 2,
    MAX_UPLOADED_VIDEO_SECONDS: 141,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
  },
  LINKEDIN: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 200 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [4096, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 4,
    MAX_UPLOADED_VIDEO_SECONDS: 900,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42", "qt"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
  },
  FACEBOOK: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS,
  INSTAGRAM: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS,
  PINTEREST: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 2048 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [4096, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 4,
    MAX_UPLOADED_VIDEO_SECONDS: 900,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42", "qt"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
  },
  TIKTOK: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 50 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [540, 540],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [4096, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 3,
    MAX_UPLOADED_VIDEO_SECONDS: 60,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42", "webm"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
  },
  THREADS: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 1024 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [1920, 1920],
    MIN_UPLOADED_VIDEO_SECONDS: 1,
    MAX_UPLOADED_VIDEO_SECONDS: 300,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0.01,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 10
  },
  leastRestrictive: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS,
  GOOGLE: NO_VIDEO_SUPPORT
};

export const VIDEO_REQUIREMENTS_BY_PROVIDER = {
  TWITTER: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 512 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    // Twitter seems to allow dimensions way beyond what they recommend, so I'm going
    // to leave the maxDimensions validation larger for now. -md 2017.04.26
    MAX_UPLOADED_VIDEO_DIMENSIONS: [4096, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 2,
    MAX_UPLOADED_VIDEO_SECONDS: 141,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
  },

  LINKEDIN: VIDEO_REQUIREMENTS_BY_PLATFORM["LINKEDIN"],
  LINKEDIN_COMPANY: VIDEO_REQUIREMENTS_BY_PLATFORM["LINKEDIN"],

  FACEBOOK: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS,
  FACEBOOK_GROUP: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS,
  FACEBOOK_PAGE: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS,
  GOOGLE_BUSINESS: NO_VIDEO_SUPPORT,
  GOOGLE: NO_VIDEO_SUPPORT,

  INSTAGRAM_BUSINESS: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 100 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [1920, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 3,
    MAX_UPLOADED_VIDEO_SECONDS: 60,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42", "qt"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0.56,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 1.78
  },
  INSTAGRAM_PERSONAL: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS,
  INSTAGRAM_BUSINESS_REELS: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 992 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [1920, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 3,
    MAX_UPLOADED_VIDEO_SECONDS: 900,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42", "qt"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0.01,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 10.1
  },

  INSTAGRAM_DIRECT: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 100 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [1920, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 3,
    MAX_UPLOADED_VIDEO_SECONDS: 60,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42", "qt"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
  },
  INSTAGRAM_MOBILE: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS,

  PINTEREST: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 2048 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [4096, 4096],
    MIN_UPLOADED_VIDEO_SECONDS: 4,
    MAX_UPLOADED_VIDEO_SECONDS: 900,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42", "qt"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 0
  },
  TIKTOK: VIDEO_REQUIREMENTS_BY_PLATFORM["TIKTOK"],
  THREADS: {
    SUPPORTS_VIDEO: true,
    MAX_UPLOADED_VIDEO_BYTES: 1024 * MEGABYTE,
    MIN_UPLOADED_VIDEO_DIMENSIONS: [32, 32],
    MAX_UPLOADED_VIDEO_DIMENSIONS: [1920, 1920],
    MIN_UPLOADED_VIDEO_SECONDS: 1,
    MAX_UPLOADED_VIDEO_SECONDS: 300,
    UPLOADED_VIDEO_SUPPORTED_FORMATS: ["mp41", "mp42"],
    MIN_UPLOADED_VIDEO_ASPECT_RATIO: 0.01,
    MAX_UPLOADED_VIDEO_ASPECT_RATIO: 10
  },

  leastRestrictive: LEAST_RESTRICTIVE_VIDEO_REQUIREMENTS
};

export const PLATFORMS_REQUIRING_MEDIA = ["INSTAGRAM", "PINTEREST"];

export const PLATFORMS_SUPPORTING_LINK_PREVIEWS = ["FACEBOOK", "LINKEDIN"];

export const PLATFORM_MIN_IMAGE_COUNT: { [key: Platform]: number } = {
  TWITTER: 0,
  FACEBOOK: 0,
  LINKEDIN: 0,
  INSTAGRAM: 0,
  PINTEREST: 0,
  GOOGLE: 0,
  TIKTOK: 0,
  THREADS: 0
};

export const PLATFORM_MAX_IMAGE_COUNT: { [key: Platform]: number } = {
  TWITTER: 4,
  FACEBOOK: 20,
  LINKEDIN: 9, // https://www.linkedin.com/help/linkedin/answer/100983/sharing-photos-or-videos?lang=en
  INSTAGRAM: 10,
  PINTEREST: 1,
  GOOGLE: 1,
  TIKTOK: 0,
  THREADS: 10
};

export const COMPOSER_MAX_IMAGE_COUNT: { [key: string]: number } = {
  TWITTER: 4,
  FACEBOOK: 20,
  LINKEDIN: 9, // https://www.linkedin.com/help/linkedin/answer/100983/sharing-photos-or-videos?lang=en
  INSTAGRAM_DIRECT: 10,
  INSTAGRAM_MOBILE: 10,
  PINTEREST: 1,
  GOOGLE: 1,
  TIKTOK: 0,
  THREADS: 10
};

export const PLATFORM_MAX_VIDEO_COUNT: { [key: Platform]: number } = {
  TWITTER: 1,
  FACEBOOK: 1,
  LINKEDIN: 1,
  INSTAGRAM: 1,
  PINTEREST: 1,
  GOOGLE: 1,
  TIKTOK: 1,
  THREADS: 1
};

export const PLATFORM_DISPLAY_NAME_MAP: { [key: Platform]: string } = {
  TWITTER: "Twitter",
  FACEBOOK: "Facebook",
  LINKEDIN: "LinkedIn",
  INSTAGRAM: "Instagram",
  PINTEREST: "Pinterest",
  GOOGLE: "Google",
  TIKTOK: "TikTok",
  THREADS: "Threads"
};

export const PROVIDER_DISPLAY_NAME_MAP: {
  [key:
    | Provider
    | "INSTAGRAM_DIRECT"
    | "INSTAGRAM_MOBILE"
    | "INSTAGRAM_BUSINESS_REELS"]: string
} = {
  TWITTER: "Twitter",
  FACEBOOK: "Facebook",
  FACEBOOK_PAGE: "Facebook Page",
  FACEBOOK_GROUP: "Facebook Group",
  LINKEDIN: "LinkedIn",
  LINKEDIN_COMPANY: "LinkedIn Company",
  INSTAGRAM_BUSINESS: "Instagram Business",
  INSTAGRAM_BUSINESS_REELS: "Instagram Reels",
  INSTAGRAM_PERSONAL: "Instagram Personal",
  INSTAGRAM_DIRECT: "Instagram Direct",
  INSTAGRAM_MOBILE: "Instagram Mobile",
  PINTEREST: "Pinterest",
  GOOGLE: "Google",
  GOOGLE_BUSINESS: "Google Business",
  TIKTOK: "TikTok",
  THREADS: "Threads"
};

export const PLATFORM_ACCOUNT_LIMITS: { [key: Platform]: ?number } = {
  TWITTER: 1,
  FACEBOOK: null,
  LINKEDIN: null,
  INSTAGRAM: null,
  PINTEREST: null,
  GOOGLE: null,
  TIKTOK: null,
  THREADS: null
};

export const SCRAPE_STATUSES = {
  BAD_URL: "Bad URL",
  NO_CONTENT: "No Content",
  SERVER_ERROR: "Server Error",
  FORBIDDEN: "Forbidden",
  PAGE_NOT_FOUND: "Page Not Found",
  UNSUPPORTED_MIME_TYPE: "Unsupported Mime Type",
  TIMED_OUT: "Timed Out",
  UNKNOWN_ERROR: "Unknown Error",
  OK: "ok",
  WAITING: "waiting"
};

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
export const SHORT_DAYS: string[] = DAYS.map(d => d.slice(0, 3));

export type ScrapeStatus =
  | "Bad URL"
  | "No Content"
  | "Server Error"
  | "Forbidden"
  | "Page Not Found"
  | "Unsupported Mime Type"
  | "Timed Out"
  | "Unknown Error"
  | "ok"
  | "waiting"
  | null;

export const RSS_IMPORT_STATUSES = {
  UNKNOWN_ERROR: "Unknown Error",
  SERVER_ERROR: "Server Error",
  OK: "ok",
  WAITING: "waiting"
};

export type RssImportStatus =
  | "Unknown Error"
  | "ok"
  | "Server Error"
  | "waiting"
  | null;

export const EDGAR_URL = "https://app.meetedgar.com";

export const DEFAULT_CATEGORY_NAME = "General";
export const IMPORT_CATEGORY_NAME = "My blog posts";
